import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/fHv7qHcOsx8">
    <SEO title="Honor Your Dad and Mom - The Big 10" />
  </Layout>
)

export default SermonPost
